<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    >
      <template #title-right>
        <v-blacker-layout align-center>
          <v-blacker-spacer />
          <span
            class="vbt-margin-r-10"
            style="font-size: 17px;"
          >
            All accounts
          </span>
          <v-blacker-switch
            color="info"
            class="custom-search-toggle"
            @change="changeAccountsState"
          />
        </v-blacker-layout>
      </template>
    </vbt-search>

    <vbt-content-box
      :loading="isLoading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="fulfilments"
      >
        <template #orderId="{ item: { orderId } }">
          <router-link
            :to="{
              name: 'order',
              params: { id: orderId },
            }"
            class="blue--text darken-1"
            target="_blank"
          >
            {{ orderId }}
          </router-link>
        </template>

        <template #status="{ item: { status } }">
          <fulfilment-status-label :status="status" />
        </template>

        <template #printCustomerId="{ item: { printCustomerId: accId } }">
          {{ accountNameById[accId] }}
        </template>

        <template #printProviderId="{ item: { printProviderId: accId } }">
          {{ accountNameById[accId] }}
        </template>

        <template #shipDates="{ item: { expectedShipDateUtc, claimedShipDateUtc,
          actualShipDateUtc, shipHoursLate } }">
          {{ formatDisplayDateOnly(expectedShipDateUtc) }} /
          {{ formatDisplayDateOnly(claimedShipDateUtc) }} /
          {{ formatDisplayDateOnly(actualShipDateUtc) }}
          <FulfilmentLateLabel
            v-if="shipHoursLate > 0"
            :hours="shipHoursLate" />
        </template>

        <template #deliveryDates="{ item: { requestedDeliveryDateLocal, expectedDeliveryDateLocal,
          actualDeliveryDateLocal, deliveryHoursLate } }">
          {{ formatDisplayDateOnly(requestedDeliveryDateLocal) }} /
          {{ formatDisplayDateOnly(expectedDeliveryDateLocal) }} /
          {{ formatDisplayDateOnly(actualDeliveryDateLocal) }}
          <FulfilmentLateLabel
            v-if="deliveryHoursLate > 0"
            :hours="deliveryHoursLate" />
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { formatDisplayDateOnly } from '@helpers';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';
import FulfilmentStatusLabel from '../_components/FulfilmentStatusLabel.vue';
import FulfilmentLateLabel from '../_components/FulfilmentLateLabel.vue';

const dataLoadingOptions = { getterName: 'getFulfilments' };
const paginationOptions = { itemsName: 'fulfilments' };
const searchOptions = {
  initialSearchDataStates: [
    'status', 'type', 'reference', 'printCustomerId', 'printProviderId', 'lateShipment', 'notDeliveredInTime',
  ],
  customTypes: {
    status: 'number',
    printCustomerId: 'number',
    printProviderId: 'number',
  },
};

const headers = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Status', value: 'status' },
  { text: 'Client', value: 'printCustomerId' },
  { text: 'Provider', value: 'printProviderId' },
  { text: 'Expected/Claimed/Actual Ship Date', value: 'shipDates' },
  { text: 'Requested/Expected/Actual Delivery Date', value: 'deliveryDates' },
]);

export default {
  name: 'IrFulfilments',

  components: {
    FulfilmentStatusLabel,
    FulfilmentLateLabel,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState('fulfilments', ['fulfilments']),

    ...mapState('enums', {
      fulfilmentStatusSelectOptions(state) {
        const { fulfilmentStatuses = [] } = state.enums;
        return fulfilmentStatuses.map(({ name: text, value }) => ({ text, value }));
      },
    }),

    ...mapGetters('enums', ['fulfilmentStatusByStatusId']),
    ...mapGetters('accounts', ['clientsOptions', 'providersOptions', 'accountNameById']),

    searchFieldsConfig() {
      return [
        [
          {
            value: 'orderId',
            label: 'Order Id',
            type: 'text',
            flex: 'sm2',
          },
          {
            value: 'status',
            items: this.fulfilmentStatusSelectOptions,
            label: 'Status',
            type: 'combobox',
            flex: 'sm2',
          },
          {
            value: 'printCustomerId',
            items: this.clientsOptions,
            label: 'Client',
            type: 'combobox',
            flex: 'sm3',
          },
          {
            value: 'printProviderId',
            items: this.providersOptions,
            label: 'Provider',
            type: 'combobox',
            flex: 'sm3',
          },
        ],
        [
          {
            value: 'lateShipment',
            label: 'Shipped Late',
            type: 'toggle',
            flex: 'sm2',
          },
          {
            value: 'notDeliveredInTime',
            label: 'Late Delivery',
            type: 'toggle',
            flex: 'sm2',
          },
        ]];
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('fulfilments', ['getFulfilments']),

    ...mapActions('accounts', ['getAccounts']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    formatDisplayDateOnly,
  },
};
</script>
