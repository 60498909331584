<template>
  <v-chip
    :color="color"
    small
  >
    {{ fulfulmentStatusByStatusId[status] }}
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex';

const STATUS_COLORS = {
  inprogress: 'grey',
  onhold: 'grey',
  produced: 'light-blue',
  shipping: 'light-blue',
  intransit: 'light-blue',
  delivered: 'success',
  canceled: 'error',
  notrouted: 'error',
};

export default {
  name: 'FulfilmentStatusLabel',

  props: {
    status: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters('enums', ['fulfulmentStatusByStatusId']),

    color() {
      return STATUS_COLORS[(this.fulfulmentStatusByStatusId[this.status] || '').toLowerCase()];
    },
  },
};
</script>
