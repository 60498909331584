<template>
  <v-chip
    v-if="hours > 0"
    :color="color"
    small
  >
    {{ hours }}h late
  </v-chip>
</template>

<script>

const STATUS_COLORS = [
  { max: 0, color: 'grey' },
  { max: 24, color: 'warning' },
  { max: 48, color: 'warning darken-2' },
  { max: 72, color: 'error' },
  { max: 96, color: 'error darken-2' },
];

export default {
  name: 'FulfilmentLateLabel',

  props: {
    hours: {
      type: Number,
      required: true,
    },
  },

  computed: {
    color() {
      return STATUS_COLORS.filter(x => x.max > this.hours)[0].color;
    },
  },
};
</script>
