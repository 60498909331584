var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-blacker-layout',{attrs:{"align-center":""}},[_c('v-blacker-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState}})],1)]},proxy:true}]),model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.fulfilments},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var orderId = ref.item.orderId;
return [_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: orderId },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(orderId)+" ")])]}},{key:"status",fn:function(ref){
          var status = ref.item.status;
return [_c('fulfilment-status-label',{attrs:{"status":status}})]}},{key:"printCustomerId",fn:function(ref){
          var accId = ref.item.printCustomerId;
return [_vm._v(" "+_vm._s(_vm.accountNameById[accId])+" ")]}},{key:"printProviderId",fn:function(ref){
          var accId = ref.item.printProviderId;
return [_vm._v(" "+_vm._s(_vm.accountNameById[accId])+" ")]}},{key:"shipDates",fn:function(ref){
        var ref_item = ref.item;
        var expectedShipDateUtc = ref_item.expectedShipDateUtc;
        var claimedShipDateUtc = ref_item.claimedShipDateUtc;
        var actualShipDateUtc = ref_item.actualShipDateUtc;
        var shipHoursLate = ref_item.shipHoursLate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateOnly(expectedShipDateUtc))+" / "+_vm._s(_vm.formatDisplayDateOnly(claimedShipDateUtc))+" / "+_vm._s(_vm.formatDisplayDateOnly(actualShipDateUtc))+" "),(shipHoursLate > 0)?_c('FulfilmentLateLabel',{attrs:{"hours":shipHoursLate}}):_vm._e()]}},{key:"deliveryDates",fn:function(ref){
        var ref_item = ref.item;
        var requestedDeliveryDateLocal = ref_item.requestedDeliveryDateLocal;
        var expectedDeliveryDateLocal = ref_item.expectedDeliveryDateLocal;
        var actualDeliveryDateLocal = ref_item.actualDeliveryDateLocal;
        var deliveryHoursLate = ref_item.deliveryHoursLate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateOnly(requestedDeliveryDateLocal))+" / "+_vm._s(_vm.formatDisplayDateOnly(expectedDeliveryDateLocal))+" / "+_vm._s(_vm.formatDisplayDateOnly(actualDeliveryDateLocal))+" "),(deliveryHoursLate > 0)?_c('FulfilmentLateLabel',{attrs:{"hours":deliveryHoursLate}}):_vm._e()]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }